<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        $t("MANUFACTURER.NOT_FOUND")
      }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{
                        edit_mode
                        ? add_new
                        ? $t("MANUFACTURER.NEW")
                        : $t("MANUFACTURER.EDIT")
                        : $t("MANUFACTURER.VIEW")
                        }}
                    </h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">{{
            manufacturer.name
          }}</span>
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                            v-on:click="save()"
                            class="btn btn-primary px-15 mr-2"
                            id="manufacturer_save"
                    >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                    >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                    >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="manufacturer_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">

                            <div class="form-group row">
                                <label
                                        class="col-xl-3 col-lg-3 col-form-label text-right"
                                        :class="edit_mode ? 'required' : ''"
                                >{{ $t("MANUFACTURER.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                            ref="name"
                                            name="name"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="manufacturer.name"
                                    />
                                </div>
                            </div>

                            <!-- MANUFACTURE -->

                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("MANUFACTURER.STREET")
                                    }}</label>
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                            ref="street"
                                            name="street"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="manufacturer.street"
                                    />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("MANUFACTURER.CITY")
                                    }}</label>
                                <div class="col-lg-9 col-xl-6">
                                    <div class="input-group input-group-md input-group-solid">
                                        <input
                                                ref="city"
                                                name="city"
                                                class="form-control form-control-md form-control-solid"
                                                type="text"
                                                v-model="manufacturer.city"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("MANUFACTURER.ZIP")
                                    }}</label>
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                            ref="zip"
                                            name="zip"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="manufacturer.zip"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("MANUFACTURER.COUNTRY")
                                    }}</label>
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                            ref="country"
                                            name="country"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="manufacturer.country"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("MANUFACTURER.CODE")
                                    }}</label>
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                            ref="code"
                                            name="code"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="manufacturer.code"
                                    />
                                </div>
                            </div>
                            <div class="form-group mb-0 row">
                                <label class="col-xl-3 col-lg-3 col-form-label text-right">{{
                                    $t("MANUFACTURER.COMMENT")
                                    }}</label>
                                <div class="col-lg-9 col-xl-6">
                                      <textarea
                                                              rows="1"
                                                              ref="comment"
                                                              name="comment"
                                                              class="form-control form-control-md form-control-solid"
                                                              type="text"
                                                              v-model="manufacturer.comment"
                                                      />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>


    </div>
</template>

<script>
    import {
        ADD_BREADCRUMB,
        SET_BREADCRUMB,
    } from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import KTUtil from "@/assets/js/components/util";

    export default {
        name: "manufacturer",
        data() {
            return {
                add_new: false,
                edit_mode: false,
                not_found: false,

                original_manufacturer: null,
                manufacturer: {
                    id: "",
                    name: "",
                    street: "",
                    city: "",
                    zip: "",
                    country: "",
                    code: "",
                    comment: "",

                },
            };
        },
        mounted() {

            if (this.$route.params.id == "new") {

                this.add_new = true;
                this.edit_mode = true;
                this.$store.dispatch(ADD_BREADCRUMB, [{title: this.$t("MANUFACTURER.NEW")}]);
            } else {
                ApiService.get("manufacturer/" + this.$route.params.id)
                    .then((data) => {

                     this.manufacturer = data.data;
                        if (this.$route.params.edit) {
                            this.edit_mode = true;
                        }
                    })
                    .catch(() => {
                        this.not_found = true;
                        this.$store.dispatch(SET_BREADCRUMB, []);
                    });
            }

            this.fv = formValidation(KTUtil.getById("manufacturer_form"), {
                fields: {
                    name: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("MANUFACTURER.NAME"),
                                }),
                            },
                        },
                    },

                },
                plugins: {
                    trigger: new Trigger(),
                    bootstrap: new Bootstrap(),
                },
            });
        },
        methods: {
            edit() {
                this.edit_mode = true;
            },
            cancel() {
                if (this.add_new) {
                    this.$router.push({
                        name: "manufacturer",
                        params: {id: this.$route.params.manufacturer_id},
                    });
                } else {

                    this.fv.resetForm(true);

                    this.edit_mode = false;
                }
            },
            save() {
                this.fv.validate().then((status) => {
                    if (status == "Valid") {
                        KTUtil.getById("manufacturer_save").classList.add(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );

                        if (this.add_new) {
                            ApiService.post(
                                "manufacturer",
                                this.manufacturer
                            )
                                .then((data) => {
                                    this.manufacturer.id = data.data.id;
                                })

                                .then(() => {
                                    KTUtil.getById("manufacturer_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                    this.$router.push({
                                        name: "manufacturers",
                                        params: {},
                                    });
                                })
                                .catch(() => {
                                    KTUtil.getById("manufacturer_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                });
                        } else {

                                ApiService.put("manufacturer/" + this.manufacturer.id, this.manufacturer)
                                .then((data) => {
                                    KTUtil.getById("manufacturer_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                    this.original_manufacturer = data.data;
                                     this.$router.push({
                                                                            name: "manufacturers",
                                                                            params: {},
                                                                        });
                                })
                                .catch(() => {
                                    KTUtil.getById("manufacturer_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                });
                        }
                    }
                });
            },

        },
        computed: {

        },

    };
</script>
